import React, { useEffect, useState } from "react";
import { Seo } from "../../components/seo";
import Layout from "../../hoc/Layout/Layout";
import Booking from "../../components/Booking/Booking";
import Instructions from "../../components/Instructions/Instructions";
import BanerSection from "../../components/BanerSection/BanerSection";
import GradeSection from "../../components/GradeSection/GradeSection";
import Iframe2 from "../../components/Iframe2/Iframe2";
import Iframe1 from "../../components/Iframe1/Iframe1";
import AdvantagesSection from "../../components/AdvantagesSection/AdvantagesSection";
import { navigate } from "gatsby";

// markup
const IndexPage = () => {
	const [bookingActive, setBookingActive] = useState(false);
	const [serviceChosen, setServiceChosen] = useState(false);
	const [ableToGoBack, setAbleToGoBack] = useState(false);
	const [finishBookingStep, setFinishBookingStep] = useState();

	useEffect(() => {
		const city = localStorage.getItem('city');
		if (city !== 'linkoping') {
			navigate('/')
		}
	}, []);

	return (
		<Layout
			bookingActive={bookingActive}
			serviceChosen={serviceChosen}
			ableToGoBack={ableToGoBack}
			finishBookingStep={finishBookingStep}
			setFinishBookingStep={setFinishBookingStep}
		>
			<Seo title="Städfirma i Jönköping | Miljövänlig Städfirma - Städföretag DreamClean" />
			<Booking
				setBookingActive={setBookingActive}
				firstStep={1}
				setServiceChosen={setServiceChosen}
				setAbleToGoBack={setAbleToGoBack}
				finishBookingStep={finishBookingStep}
				setFinishBookingStep={setFinishBookingStep}
				linkopingBg
			/>
			{!bookingActive && (
				<>
					<Iframe1 />
					<AdvantagesSection
						type="Index"
						text1={{
							heading: "Socialt ansvarstagande hemstädningsföretag",
							description:
								"Vi har kollektivavtal med Almega och våra anställda får självklart betald semester, pension, friskvårdsbidrag och andra förmåner.",
						}}
						text2={{
							heading: "Inga bindningstider",
							description:
								"Våra kunder stannar för att tjänsten är bra, inte på grund av långa bindningstider.  Pausa ditt abonnemang när du vill, du är alltid välkommen tillbaka.",
						}}
						text3={{
							heading: "Alltid samma städare",
							description:
								"En av våra utbildade städproffs väljs noggrant ut och städar hos dig samma tid och samma veckodag. Detta för att lära känna ditt hem och utföra högsta kvalitet av städning. ",
						}}
						text4={{
							heading: "Enkel onlinebokning",
							description:
								"Att skaffa städhjälp ska vara enkelt. Boka ett kostnadsfritt startmöte från Dreamclean eller skicka bokningsförfrågan för våra andra tjänster enkelt online.",
						}}
					/>
					<Instructions
						bg
						linkopingHeader={"Så kommer du igång med städning i Linköping"}
						linkopingDescription={"Det ska vara enkelt att skaffa städhjälp. Vi hjälper dig komma igång med städningen i Linköping, snabbt och enkelt."}
						text1="Välj den typ av städning du önskar få hjälp med och ange hur stor din bostad är. "
						text2="Se pris och boka ett kostnadsfritt startmöte den tid som passar dig eller skicka bokningsförfrågan för våra andra tjänster."
						text3="Vi går igenom städupplägg tillsammans. Därefter kommer din städare till dig vid önskad tidpunkt och du får njuta av ett rent och snyggt hem. "
					/>
					<GradeSection />
					<Iframe2 />
					<BanerSection linkopingHome />
				</>
			)}
		</Layout>
	);
};

export default IndexPage;
