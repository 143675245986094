import React from "react";

const Iframe1 = () => {
	return (
		<div class="iframe-wrapper bg-gray-50">
			<div class="inner-wrapper bg-gray-50">
				<iframe
					src="https://widget.reco.se/v2/widget/4025944?mode=HORIZONTAL&border=false&bg=fafafa"
					width="100%"
					height="100%"
					scrolling="no"
					title="iframe1"
					// style="border:0;display:block"
					data-reactroot=""
				></iframe>
			</div>
		</div>
	);
};

export default Iframe1;
