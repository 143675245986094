import React from "react";

const Iframe2 = () => {
	return (
		<>
			<p
				class="mt-2 text-3xl px-4 font-extrabold text-gray-900 tracking-tight sm:text-4xl ta-center"
				style={{ paddingTop: 100 }}
			>
				Vi finns här för dig med 100% nöjdhetsgaranti
			</p>
			<p class="mt-5 px-4 max-w-prose mx-auto text-xl text-gray-500 ta-center">
				Efter flera tusen städningar vet vi vad våra kunder värderar: god
				service, hög kvalitet och rätt attityd. Om du inte är nöjd med din
				städning ser vi till att rätta till det. Vi har öppen kundservice 5
				dagar i veckan.
			</p>
			<div class="iframe2-wrapper" style={{ paddingBottom: 20 }}>
				<iframe
					src="https://widget.reco.se/v2/widget/4025944?mode=HORIZONTAL_QUOTE"
					width="100%"
					height="225"
					scrolling="no"
					title="iframe2"
					// style="border:0;display:block"
					data-reactroot=""
				></iframe>
			</div>
		</>
	);
};

export default Iframe2;
